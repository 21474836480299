import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { confirmDialog } from 'primereact/confirmdialog';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ImageUpload from '../common/ImageUpload';
import { InputNumber } from 'primereact/inputnumber';

export default function Banner({
  bannerList,
  inputs,
  onChange,
  reset,
  postBanner,
  updateBanner,
  updateBannerText,
  deleteBanner,
  images,
  setImages,
}) {
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');

  const handleUpdate = ({ data }) => {
    setVisible(true);
    setUpdate(true);
    reset({ ...data });
    setId(data.id);
    setImages([]);
  };

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Banner</span>

        <Button label='Add Banner' onClick={() => setVisible(true)} />
      </div>

      <DataTable
        value={bannerList}
        // paginator
        // rows={10}
        tableStyle={{ minWidth: '50rem' }}
        onRowClick={handleUpdate}
      >
        <Column field='title' header='Title' style={{ width: '20%' }} />
        <Column field='subtitle' header='Sub title' style={{ width: '10%' }} />
        <Column field='text' header='Text' style={{ width: '20%' }} />
        <Column field='subtext' header='Sub text' style={{ width: '20%' }} />
        <Column
          header='Image (Click to see image.)'
          style={{ width: '10%' }}
          body={(banner) => {
            return (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  window.open(banner.image, '_blank');
                }}
              >
                {banner.image}
              </span>
            );
          }}
        />
        <Column
          header='Status'
          style={{ width: '10%' }}
          body={(banner) => (
            <SelectButton
              value={banner.is_banner ? 'on' : 'off'}
              onChange={(e) => {
                updateBanner(
                  banner.id,
                  e.value === 'on' ? true : false,
                  'is_banner'
                );
              }}
              options={['on', 'off']}
            />
          )}
        />
        <Column
          header='Order number'
          style={{ width: '10%' }}
          body={(banner) => (
            <InputNumber
              inputId='integeronly'
              value={banner.order_num}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  updateBanner(banner.id, Number(e.target.value));

                  window.location.reload();
                }
              }}
            />
          )}
        />
      </DataTable>
      <Dialog
        draggable={false}
        header='Banner'
        visible={visible}
        style={{ width: '40vw', height: '70vh' }}
        onHide={() => {
          setVisible(false);
          reset();
          setImages([]);
        }}
        footer={() => {
          return (
            <div>
              <Button
                label={update ? 'Delete' : 'No'}
                icon='pi pi-times'
                onClick={() => {
                  if (update) {
                    deleteBanner(id);
                  }
        
                  setVisible(false);
                  setUpdate(false);
                  reset();
                  setImages([]);
                }}
                severity='danger'
                text
              />
              <Button
                label={'Yes'}
                icon='pi pi-check'
                onClick={() => {
                  if (update) {
                    updateBannerText(id);
                  } else {
                    postBanner();
                  }

                  setVisible(false);
                  reset();
                  setImages([]);
                }}
                autoFocus
              />
            </div>
          );
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <InputText
            placeholder='title'
            style={{ width: '70%', marginBottom: '20px' }}
            name='title'
            value={inputs.title}
            onChange={onChange}
          />

          <InputText
            placeholder='sub title'
            style={{ width: '70%', marginBottom: '20px' }}
            name='subtitle'
            value={inputs.subtitle}
            onChange={onChange}
          />

          <InputText
            placeholder='text'
            style={{ width: '70%', marginBottom: '20px' }}
            name='text'
            value={inputs.text}
            onChange={onChange}
          />

          <InputText
            placeholder='sub text'
            style={{ width: '70%', marginBottom: '20px' }}
            name='subtext'
            value={inputs.subtext}
            onChange={onChange}
          />

          {
            update ? <></> : <ImageUpload
            images={images}
            setImages={setImages}
            style={{ width: '70%' }}
          />
          }
        </div>
      </Dialog>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
