import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import dayjs from 'dayjs';

export default function Inquire({ inquireList, deleteInquire }) {
  const confirm = ({ data }) => {
    confirmDialog({
      message: 'Do you want to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteInquire(data.id),
      reject: () => {},
    });
  };

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Inquire</span>
      </div>

      <DataTable
        value={inquireList}
        // paginator
        // rows={10}
        tableStyle={{ minWidth: '50rem' }}
      >
        <Column
          field='first_name'
          header='First name'
          style={{ width: '10%' }}
        />
        <Column field='last_name' header='Last name' style={{ width: '10%' }} />
        <Column field='email' header='E-mail' style={{ width: '20%' }} />
        <Column
          header='Artwork (Click to see image.)'
          style={{ width: '20%' }}
          body={(inquire) => {
            return (
              <span
                onClick={(e) => {
                  window.open(inquire.artwork.thumbnail, '_blank');
                }}
              >
                {inquire.artwork.title}
              </span>
            );
          }}
        />
        <Column field='message' header='Message' style={{ width: '25%' }} />
        <Column
          header='Sent at'
          style={{ width: '8%' }}
          body={(inquire) => {
            return <>{dayjs(inquire.created_at).format('MM.DD.YYYY')}</>;
          }}
        />
        <Column
          header='Delete Button'
          style={{ width: '7%' }}
          body={(inquire) => {
            return (<span onClick={confirm}>DELETE</span>);
          }}
        />
      </DataTable>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
