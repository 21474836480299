import { useEffect, useRef, useState } from 'react';
import Component from '../../components/banner';
import useInputs from '../../hooks/useInputs';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import upload from '../../library/upload.lib';

export default function Banner() {
  const toast = useRef(null);

  const [bannerList, setBannerList] = useState([]);
  const [images, setImages] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    title: '',
    subtitle: '',
    text: '',
    subtext: '',
  });

  const getBanner = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner`)
      .then((response) => {
        setBannerList([...response.data.data]);
      });
  };

  const postBanner = async () => {
    if (!images.length) return;

    await upload(images[0], `${process.env.REACT_APP_ENVIRONMENT}/banner`)
      .then((s3_response) => {
        axios
          .post(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner`, {
            ...inputs,
            image: `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/banner/${s3_response.timestamp}.jpeg`,
          })
          .then((response) => {
            if (response.data.status === 200) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Added Banner.',
                life: 3000,
              });
              getBanner();
              reset();
              setImages([]);
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateBanner = async (id, data, type) => {
    if (type === 'is_banner') {
      axios
        .put(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner`, {
          id,
          is_banner: data,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Banner.',
              life: 3000,
            });

            getBanner();
          }
        });

      return;
    }

    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner`, {
        id,
        order_num: data,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Banner.',
            life: 3000,
          });

          getBanner();
        }
      });
  };

  const updateBannerText = (id) => {
    axios
        .put(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner`, {
          id,
          ...inputs
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Banner.',
              life: 3000,
            });

            getBanner();
          }
        });
  }

  const deleteBanner = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Banner.',
            life: 3000,
          });
          getBanner();
          reset();
        }
      });
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
      <Component
        bannerList={bannerList}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postBanner={postBanner}
        updateBanner={updateBanner}
        updateBannerText={updateBannerText}
        deleteBanner={deleteBanner}
        images={images}
        setImages={setImages}
      />
      <Toast ref={toast} />
    </>
  );
}
